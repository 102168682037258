<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {Channels} from "@/utils/Channels";
import AuthService from '../service/AmplifyAuth';
import TrustdService from "@/service/TrustdService";

const { onMenuToggle } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const isUserSignedIn = ref(false);
const channelLogo = ref('');
const channelLink = ref('');
const router = useRouter();
const store = useStore();

let user = ref(null);

onMounted(() => {
    bindOutsideClickListener();
    getChannelLogo();
    getUserFromStripe();
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const getUserFromStripe = async () => {
  user.value = await TrustdService.getUserInformationByHostEmail();
}

const getChannelLogo = async () => {
    if (store?.state) {
        isUserSignedIn.value = store.state.isUserSignedIn;
    }

    if (isUserSignedIn.value) {
        const logoServerAddress = 'https://d2gl8sw234uklp.cloudfront.net/channel/';
        const userAttributes = await AuthService.currentAuthenticatedUserAttributes();
        if (userAttributes?.channel) {
          channelLogo.value = logoServerAddress + userAttributes.channel + '.png';
          channelLink.value = Channels[userAttributes.channel];
        } else {
          channelLogo.value = logoServerAddress + 'trustd.png';
          channelLink.value = Channels["trustd"];
        }
    }
};
const onLogoutClick = async () => {
    topbarMenuActive.value = false;
    await AuthService.signOut();
    if (store?.state) {    
        store.commit('shouldHideMenuNavigation', false);
    }
};
const onBackToHomeButton = () => {
    topbarMenuActive.value = false;
    router.push('/');
}
const onAccountButton = () => {
    topbarMenuActive.value = false;
    router.push('/account');
}
const onTopUpButton = () => {
    topbarMenuActive.value = false;
    router.push('/topup');
}
const onTopBarMenuButton = () => {
    topbarMenuActive.value = !topbarMenuActive.value;
};
const topbarMenuClasses = computed(() => {
    return {
        'layout-topbar-menu-mobile-active': topbarMenuActive.value
    };
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarMenuActive.value) return;

    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};
</script>

<template>
    <div class="layout-topbar">
        <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle()">
            <i class="pi pi-bars"></i>
        </button>
        
        <router-link to="/" class="layout-topbar-logo">
            <div v-if="isUserSignedIn">
                <a :href="channelLink" target="_blank">
                    <img alt="Channel Logo" :src="channelLogo" height="40" class="p-mr-2">
                </a>
            </div>
        </router-link>

        <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
            <i class="pi pi-ellipsis-v"></i>
        </button>

        <div class="layout-topbar-menu" :class="topbarMenuClasses" v-if="user">
            <button @click="onBackToHomeButton()" class="p-link layout-topbar-button">
                <i class="pi pi-fw pi-home"></i>
                <span>Home</span>
            </button>
            <button @click="onAccountButton()" class="p-link layout-topbar-button displayable">
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="rgba(79, 79, 79, 1)"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-user"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
                <span>{{ user.contactName }}</span>
            </button>
            <button @click="onTopUpButton()" class="p-link layout-topbar-button displayable">
              <svg width="45" height="31" viewBox="0 0 45 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.2709 0.72161C20.003 0.716102 15.9088 1.74882 11.8698 3.85097C7.83712 5.95311 4.64076 8.70702 2.76443 11.3967C0.887181 14.0771 0.379544 16.574 1.30302 18.3549C2.22742 20.1266 4.56456 21.1455 7.84079 21.1455C11.117 21.1547 15.2112 20.1174 19.241 18.0244C23.2801 15.9223 26.4746 13.1592 28.3473 10.4787C30.2291 7.79823 30.7248 5.30136 29.8069 3.5205C28.8797 1.74882 26.5481 0.72161 23.2709 0.72161ZM31.7071 7.17401C31.3858 8.5785 30.6881 10.0197 29.7059 11.4242C27.6313 14.3892 24.244 17.2808 20.003 19.484C15.7619 21.6963 11.4567 22.807 7.83988 22.807C6.13245 22.807 4.54988 22.55 3.21607 22.0084L4.1882 23.881C5.11351 25.6619 7.44423 26.6808 10.7223 26.6808C13.9994 26.6808 18.0936 25.6527 22.1235 23.5506C26.1625 21.4576 29.3571 18.6945 31.2297 16.0049C33.1024 13.3244 33.6164 10.8275 32.6893 9.05585L31.7071 7.17401ZM34.3508 8.7162C35.3789 11.1672 34.5528 14.1506 32.5883 16.9504C30.8534 19.4381 28.2004 21.8523 24.9141 23.8719C25.933 23.982 26.9979 24.0371 28.0811 24.0371C32.625 24.0371 36.7375 23.0641 39.6383 21.5494C42.5483 20.0348 44.1455 18.0519 44.1455 16.0508C44.1455 14.0496 42.5483 12.0668 39.6383 10.5521C38.1604 9.78104 36.3612 9.14764 34.3508 8.7162ZM44.1455 20.1724C43.2092 21.2648 41.9241 22.2287 40.4094 23.0182C37.1965 24.6889 32.8637 25.6894 28.0811 25.6894C26.135 25.6894 24.2623 25.5242 22.509 25.2121C20.3793 26.2861 18.2405 27.0848 16.1842 27.608C16.2944 27.6723 16.4045 27.7273 16.5239 27.7916C19.4246 29.3062 23.5371 30.2793 28.0811 30.2793C32.625 30.2793 36.7375 29.3062 39.6383 27.7916C42.5483 26.2769 44.1455 24.2941 44.1455 22.293V20.1724Z" fill="#EDD816"/>
              </svg>
                <span>{{ user.screening }} Credits</span>
            </button>
            <button @click="onLogoutClick()" class="p-link layout-topbar-button">
                <i class="pi pi-fw pi-sign-out"></i>
                <span>LogOut</span>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped></style>
