<template>
  <div class="signupBody">
    <div class="container-xxl">
      <div class="signupLeft">
        <div class="signupLeftLogo">
          <img
            alt="Trustd logo"
            src="../assets/trustd-logo.png"
            height="30"
            class="p-mr-1 logo-blend"
          />
        </div>
        <h2>Welcome To The Trustd.ai Host Portal</h2>
        <p>
          The First GIG Score for Short Term Rental Vertical, based on Behavior
          of guests during their stay.
        </p>
      </div>
      <div>
        <div class="signupLeft mobile">
          <div class="signupLeftLogo">
            <img
              alt="Trustd logo"
              src="../assets/trustd-logo.png"
              height="67"
              class="p-mr-1 logo-blend"
            />
          </div>
          <h2>Welcome To The Trustd.ai Host Portal</h2>
          <p>
            The First GIG Score for Short Term Rental Vertical, based on
            Behavior of guests during their stay.
          </p>
        </div>
        <div class="cardContainer">
          <h2 class="cardTitle" v-if="currentStage === STAGE_SIGNUP">
            1. Personal Details
          </h2>
          <Card
            class="signupCard cardCenter cardW50"
            v-if="currentStage === STAGE_SIGNUP"
          >
            <template #content>
              <Dialog
                v-model:visible="visible"
                modal
                header="Informations"
                :style="{ width: '25rem' }"
              >
                <span class="p-text-secondary block mb-5"
                  >Complete your registration.</span
                >
                <div class="flex align-items-center gap-3 mb-3">
                  <label for="accountId" class="font-semibold w-6rem"
                    >Account ID</label
                  >
                  <InputText
                    id="accountId"
                    class="flex-auto"
                    autocomplete="off"
                    v-model="signupForm.accountId"
                  />
                </div>
                <div class="flex align-items-center gap-3 mb-5">
                  <label for="apiKey" class="font-semibold w-6rem"
                    >API Key</label
                  >
                  <InputText
                    id="apiKey"
                    class="flex-auto"
                    autocomplete="off"
                    v-model="signupForm.apiKey"
                  />
                </div>
                <div class="flex justify-content-end gap-2">
                  <Button
                    type="button"
                    label="Cancel"
                    severity="secondary"
                    @click="visible = false"
                  ></Button>
                  <Button
                    type="button"
                    label="Complete"
                    @click="complete"
                  ></Button>
                </div>
              </Dialog>
              <div class="mb-2 row">
                <div class="input-container">
                  <label for="firstName" class="form-label"
                    >First Name<sup class="validatedField">*</sup></label
                  >

                  <input
                    type="text"
                    class="form-control"
                    id="firstName"
                    placeholder="First Name"
                    v-model="signupForm.firstName"
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="input-container">
                  <label for="lastName" class="form-label"
                    >Last Name<sup class="validatedField">*</sup></label
                  >

                  <input
                    type="text"
                    class="form-control"
                    id="lastName"
                    placeholder="First Name"
                    v-model="signupForm.lastName"
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="input-container">
                  <label for="invitationCode" class="form-label"
                    >Invitation Code<sup class="validatedField">*</sup></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="invitationCode"
                    placeholder="Sign Up Invitation Code"
                    v-model="invitationCode"
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="input-container">
                  <label for="signupUsername" class="form-label"
                    >Email<sup class="validatedField">*</sup></label
                  >
                  <input
                    type="email"
                    class="form-control"
                    id="signupUsername"
                    placeholder="Email"
                    v-model="signupForm.username"
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="input-container">
                  <label for="signupPassword" class="form-label"
                    >Password<sup class="validatedField">*</sup></label
                  >
                  <input
                    type="password"
                    class="form-control"
                    id="signupPassword"
                    placeholder="Password"
                    v-model="signupForm.password"
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="input-container">
                  <label for="signupPasswordR" class="form-label"
                    >Re-Enter Password<sup class="validatedField">*</sup></label
                  >

                  <input
                    type="password"
                    class="form-control"
                    id="signupPasswordR"
                    placeholder="Re-Enter Password"
                    v-model="signupForm.passwordR"
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="input-container">
                  <label for="phone" class="form-label"
                    >Phone Number<sup class="validatedField">*</sup></label
                  >

                  <input
                    type="text"
                    class="form-control"
                    id="phone"
                    placeholder="Phone Number"
                    v-model="signupForm.phone"
                  />
                </div>
              </div>
            </template>
          </Card>

          <h2 class="cardTitle mt-8" v-if="currentStage === STAGE_SIGNUP">
            2. State & Properties
          </h2>
          <Card
            class="signupCard cardCenter cardW50"
            v-if="currentStage === STAGE_SIGNUP"
          >
            <template #content>
              <div class="mb-2 row">
                <div class="input-container">
                  <label for="pms" class="form-label"
                    >Property Management System - PMS</label
                  >

                  <input
                    type="text"
                    class="form-control"
                    id="pms"
                    placeholder='Eg: OwnerRez, etc. Type "none" If Not Using One'
                    v-model="signupForm.pms"
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="input-container">
                  <label for="numberOfProperties" class="form-label"
                    >Number Of Properties</label
                  >

                  <input
                    type="number"
                    class="form-control"
                    id="numberOfProperties"
                    placeholder="Number Of Properties"
                    v-model="signupForm.numberOfProperties"
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="input-container">
                  <label for="company" class="form-label"
                    >Company Name<sup class="validatedField">*</sup></label
                  >

                  <input
                    type="text"
                    class="form-control"
                    id="company"
                    placeholder="Company (Type “Self” If No company)"
                    v-model="signupForm.company"
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="input-container">
                  <label for="countryCodeDropDown" class="form-label"
                    >Country/Region<sup class="validatedField">*</sup></label
                  >

                  <Dropdown
                    v-model="selectedCountry"
                    :options="countryPhoneCodes"
                    optionLabel="label"
                    placeholder="Select a Country"
                    id="countryCodeDropDown"
                    class="form-select p-1"
                    scrollHeight="300px"
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="input-container">
                  <label for="state" class="form-label">State/Region</label>

                  <input
                    type="text"
                    class="form-control"
                    id="state"
                    placeholder="State/Region"
                    v-model="signupForm.state"
                  />
                </div>
              </div>
              <div class="mb-3 signupButtons">
                <div>
                  <div class="checkbox-container">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="termsAndConditions"
                      v-model="signupForm.termsAndConditions"
                      @click="checkTermsAndConditions"
                    />

                    <label
                      for="termsAndConditions"
                      class="form-check-label"
                      @click="checkTermsAndConditions"
                    >
                      By clicking here, I state that I read, understood, and
                      accepted the terms of
                      <strong> Service Agreement</strong>.
                    </label>
                  </div>
                </div>
                <div class="mt-3">
                  <Button
                    class="btn btn-primary w-100"
                    label="Sign Up"
                    @click="signup"
                  />
                </div>
                <div
                  class="mt-3"
                  style="display: flex; justify-content: center"
                >
                  <a @click="() => $router.push('/login')" href="#">
                    <span class="secondary-a">Don’t Have An Account? </span>
                    <span>Click here to Log In!</span>
                  </a>
                </div>
              </div>
            </template>
          </Card>

          <Card
            class="signupCard cardCenter cardW50"
            v-if="currentStage === STAGE_SIGNUP_VERIFY"
          >
            <template #title>
              <div class="text-center">
                <img
                  alt="Trustd logo"
                  src="../assets/trustd-logo.png"
                  height="40"
                  class="p-mr-1 logo-blend"
                />
              </div>
            </template>
            <template #content>
              <div class="text-center mb-3" style="font-weight: 700">
                Verify Your Email
              </div>

              <Dialog
                v-model:visible="visible"
                modal
                header="Informations"
                :style="{ width: '25rem' }"
              >
                <span class="p-text-secondary block mb-5"
                  >Complete your registration.</span
                >
                <div class="flex align-items-center gap-3 mb-3">
                  <label for="accountId" class="font-semibold w-6rem"
                    >Account ID</label
                  >
                  <InputText
                    id="accountId"
                    class="flex-auto"
                    autocomplete="off"
                    v-model="signupForm.accountId"
                  />
                </div>
                <div class="flex align-items-center gap-3 mb-5">
                  <label for="apiKey" class="font-semibold w-6rem"
                    >API Key</label
                  >
                  <InputText
                    id="apiKey"
                    class="flex-auto"
                    autocomplete="off"
                    v-model="signupForm.apiKey"
                  />
                </div>
                <div class="flex justify-content-end gap-2">
                  <Button
                    type="button"
                    label="Cancel"
                    severity="secondary"
                    @click="visible = false"
                  ></Button>
                  <Button
                    type="button"
                    label="Complete"
                    @click="complete"
                  ></Button>
                </div>
              </Dialog>

              <div class="mb-2 row">
                <div class="col">
                  <div class="mb-2">
                    Check your email for the verification code from Trustd Host
                    Services titled "Your Verification Code for Trustd Portal."
                    If you don't see it, look in your Junk or Spam folder. If
                    it's not there within 10 minutes, email us at
                    <a href="mailto:info@trustd.ai">info@trustd.ai</a>. Enter
                    the code below to complete signup.
                  </div>
                </div>
              </div>

              <div class="mb-2 row col-12">
                <div class="col-md-4 col-sm-12">
                  <label for="verificationCode" class="form-label"
                    >Verification Code</label
                  >
                </div>
                <div class="col-md-8 col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    id="verificationCode"
                    placeholder="Verification Code"
                    v-model="verificationCode"
                  />
                </div>
              </div>
              <div class="mb-3 row justify-content-end flex-wrap">
                <Button
                  class="btn-primary btn"
                  label="Verify"
                  @click="verify"
                />
                &nbsp;
                <Button
                  label="Reset"
                  class="btn-primary btn p-button-warning"
                  @click="resetVerify"
                />
              </div>
            </template>
          </Card>

          <Card
            class="signupCard cardCenter cardW50"
            v-if="currentStage === STAGE_SIGNUP_SUCCESS"
          >
            <template #title> SignUp Successful </template>
            <template #content>
              <div class="mb-2 row">
                <div class="col">
                  <div class="mb-2">
                    Thanks for signing up for Trustd Portal Services. Your
                    Account is ready for login.
                  </div>
                  <div class="mb-2">
                    You can find your account details below:
                  </div>
                </div>
              </div>

              <div class="mb-2 row">
                <div class="col-md-4 col-sm-12">
                  <label for="invitationCodeR" class="form-label"
                    >Invitation Code</label
                  >
                </div>
                <div class="col-md-8 col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    id="invitationCodeR"
                    placeholder="sign up invitation code"
                    v-model="invitationCode"
                    readonly
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="col-md-4 col-sm-12">
                  <label for="signupUsernameR" class="form-label"
                    >Username</label
                  >
                </div>
                <div class="col-md-8 col-sm-12">
                  <input
                    type="email"
                    class="form-control"
                    id="signupUsernameR"
                    placeholder="user email address"
                    v-model="signupForm.username"
                    readonly
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="col-md-4 col-sm-12">
                  <label for="contactNameR" class="form-label">Name</label>
                </div>
                <div class="col-md-8 col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    id="contactNameR"
                    placeholder="contact name"
                    v-model="signupForm.contactName"
                    readonly
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="col-md-4 col-sm-12">
                  <label for="companyR" class="form-label">Company</label>
                </div>
                <div class="col-md-8 col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    id="companyR"
                    placeholder='company (type "Self" if no company)'
                    v-model="signupForm.company"
                    readonly
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="col-md-4 col-sm-12">
                  <label for="countryR" class="form-label">Country</label>
                </div>
                <div class="col-md-8 col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    id="countryR"
                    placeholder="country"
                    v-model="signupForm.country"
                    readonly
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="col-md-4 col-sm-12">
                  <label for="phoneR" class="form-label">Phone</label>
                </div>
                <div class="col-md-8 col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    id="phoneR"
                    placeholder="phone"
                    v-model="signupForm.phone"
                    readonly
                  />
                </div>
              </div>
              <div class="mb-2 row">
                <div class="col-md-4 col-sm-12">
                  <label for="sendEmailR" class="form-label"
                    >Score Email Opt In</label
                  >
                </div>
                <div class="col-md-8 col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    id="sendEmailR"
                    placeholder="Score Email Opt In"
                    v-model="signupForm.sendEmail"
                    readonly
                  />
                </div>
              </div>
              <div class="mb-3">
                <Button label="Login" @click="login" />
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>

  <terms-and-conditions ref="termsAndConditions"></terms-and-conditions>
</template>

<script>
import AuthService from "@/service/AmplifyAuth";
import StringUtils from "@/utils/StringUtils";
import EmailUtils from "@/utils/EmailUtils";
import TrustdService from "@/service/TrustdService";
import ErrorUtils from "@/utils/ErrorUtils";
import TermsAndConditions from "@/views/TermsAndConditions";
import { findCountryCode, getCountryPhoneCodes } from "@/utils/CountryCodes";
import { ref } from "vue";
import axios from "axios";

export default {
  name: "SignUp",
  components: { TermsAndConditions },
  data() {
    const phoneCodes = getCountryPhoneCodes();

    console.log(phoneCodes);

    return {
      STAGE_SIGNUP: "SIGNUP",
      STAGE_SIGNUP_VERIFY: "SIGNUP_VERIFY",
      STAGE_SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
      is_email_filled: ref(false),
      invitationCode: "",
      invitation: null,
      visible: ref(false),
      signupForm: {
        username: "",
        password: "",
        passwordR: "",
        country: "", // country name
        phone: "", // for display: without country code
        phoneNumber: "", // internal: for aws cognito: includes + country code
        contactName: "",
        firstName: "",
        lastName: "",
        pms: "",
        numberOfProperties: "",
        state: "",
        company: "",
        sendEmail: "YES",
        channel: "",
        termsAndConditions: false,
        accountId: "",
        apiKey: "",
      },
      verificationCode: "",
      currentStage: "",
      selectedCountry: null,
      countryPhoneCodes: phoneCodes,
    };
  },

  mounted() {
    this.currentStage = this.STAGE_SIGNUP;
    if (this.$route.params.code) {
      this.invitationCode = this.$route.params.code;
    }
    this.updateCountryCode();
    console.log("invitationCode: ", this.invitationCode);

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("is_api") && urlParams.get("is_api") === "ownerrez") {
      this.saveAfterOwnerrez();
    }

    if (urlParams.has("email")) {
      this.syncEmail(urlParams.get("email"));
    }
  },

  methods: {
    async saveAfterOwnerrez() {
      const urlParams = new URLSearchParams(window.location.search);

      this.signupForm.username = urlParams.get("reference");
      this.signupForm.channel = "ownerrez";

      this.$toast.add({
        severity: "success",
        summary: "Success",
        detail: "Sign Up Successful",
        life: 5000,
      });
      this.currentStage = this.STAGE_SIGNUP_VERIFY;
    },
    async syncEmail(email) {
      this.is_email_filled = ref(true);

      const headers = {
        "Content-Type": "application/json",
        "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2", // todo
      };

      const res = await axios.get("/stripe/users/" + email, { headers });

      const res2 = await axios.get("/stripe/user-extra/" + email, { headers });

      if (res.data) {
        console.log("user data: ", res.data);

        const contactName = res.data.contactName;
        const nameParts = contactName.split(" ");

        // Son öğe soyad olur, geri kalanlar ad
        const lastName = nameParts.pop();
        const firstName = nameParts.join(" ");

        this.signupForm.firstName = firstName;
        this.signupForm.lastName = lastName;

        this.signupForm.username = res.data.username;
        this.signupForm.company = res.data.company;
        this.signupForm.contactName = res.data.contactName;
        this.signupForm.phone = res.data.phoneNumber.substring(2);
      }

      if (res2.data) {
        this.signupForm.pms = res2.data.pms;
        this.signupForm.numberOfProperties = res2.data.numberOfProperties;
        this.signupForm.state = res2.data.state;
      }
    },
    updateCountryCode() {
      const defaultCountry = "United States";

      const country = findCountryCode(defaultCountry);

      this.selectedCountry = null;
      if (country != null) {
        this.selectedCountry = country;
      }
    },

    async signup() {
      console.log("clicked signup");

      this.updatePhoneNumberData();

      if (!this.validateSignup()) {
        return;
      }

      // first check the invitation code
      try {
        console.log("signup - checking invitation code");
        this.invitation = await TrustdService.verifyInvitationCode(
          this.invitationCode
        );
        this.signupForm.channel = this.invitation.channel;

        await TrustdService.saveUserInfo({
          username: this.signupForm.username,
          contactName:
            this.signupForm.firstName + " " + this.signupForm.lastName,
          company: this.signupForm.company,
          email: this.signupForm.username,
          website: null,
          phoneNumber: this.signupForm.phoneNumber,
          address: null,
          sendEmail: this.signupForm.sendEmail,
          channel: this.signupForm.channel,
          accountId: this.signupForm.accountId,
          apiKey: this.signupForm.apiKey,
          screening: 5,
          freeScreening: 0,
          status: "ACTIVE",
        });
      } catch (e) {
        const msg = ErrorUtils.parseError(e);
        console.error(msg, e);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Invitation Code Error: " + msg,
          life: 10000,
        });
        return;
      }

      // proceed with sign up with AWS
      try {
        console.log("signup - calling AWS signUp");
        /*
                  const { user } = await AuthService.signUp(this.signupForm);
                  this.$store.commit('signInUser', user);
          */

        await AuthService.signUp(this.signupForm);

        const headers = {
          "Content-Type": "application/json",
          "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2", // todo
        };

        const payload = {
          email: this.signupForm.username,
          pms: this.signupForm.pms,
          numberOfProperties: this.signupForm.numberOfProperties,
          state: this.signupForm.state,
          country: this.signupForm.country,
          signed: true,
        };

        await axios.post("/stripe/user-extra", payload, {
          headers,
        });

        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Sign Up Successful",
          life: 5000,
        });
        this.currentStage = this.STAGE_SIGNUP_VERIFY;
      } catch (e) {
        const msg = ErrorUtils.parseError(e);
        console.error(msg, e);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Sign Up Unsuccessful: " + msg,
          life: 10000,
        });
      }
    },

    updatePhoneNumberData() {
      if (this.selectedCountry != null) {
        this.signupForm.country = this.selectedCountry.phoneCountry;
        this.signupForm.phoneNumber =
          "+" + this.selectedCountry.phoneCode + this.signupForm.phone;
      } else {
        this.signupForm.country = null;
        this.signupForm.phoneNumber = null;
      }
    },

    validateSignup() {
      let valid = true;

      if (StringUtils.isEmpty(this.invitationCode)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Invitation Code is required",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.signupForm.username)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Username (email address) is required",
          life: 5000,
        });
        valid = false;
      }

      if (!EmailUtils.isValid(this.signupForm.username)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Username is not a valid email address",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.signupForm.password)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Password is required",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.signupForm.passwordR)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Password (re-entry) is required",
          life: 5000,
        });
        valid = false;
      }

      if (this.signupForm.password !== this.signupForm.passwordR) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Password and Password Re-Entry do not match!",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.signupForm.lastName)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Last Name is required",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.signupForm.firstName)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "First Name is required",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.signupForm.company)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Company is required",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.signupForm.country)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Country is required",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.signupForm.phone)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Phone Number is required",
          life: 5000,
        });
        valid = false;
      }

      if (!this.signupForm.termsAndConditions) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Terms and Conditions should be accepted",
          life: 5000,
        });
        valid = false;
      }

      return valid;
    },

    async verify() {
      console.log("Verify was initiated");
      if (!this.validateVerify()) {
        return;
      }
      try {
        console.log(this.signupForm.username, this.verificationCode);

        await AuthService.confirmSignUp(
          this.signupForm.username,
          this.verificationCode
        );

        await TrustdService.saveUserInfo({
          username: this.signupForm.username,
          contactName:
            this.signupForm.firstName + " " + this.signupForm.lastName,
          company: this.signupForm.company,
          email: this.signupForm.username,
          website: null,
          phoneNumber: this.signupForm.phoneNumber,
          address: null,
          sendEmail: this.signupForm.sendEmail,
          channel: this.signupForm.channel,
          accountId: this.signupForm.accountId,
          apiKey: this.signupForm.apiKey,
          screening: 5,
          freeScreening: 0,
          status: "ACTIVE",
        });

        const user = await AuthService.signIn(
          this.signupForm.username,
          this.signupForm.password
        );

        console.log("User logged in: ", user);

        this.$store.commit("signInUser", user);

        if (this.signupForm.channel == "ownerrez") {
          console.log("user saved");

          const res = await TrustdService.ownerrezTempUserCreate({
            email: this.signupForm.username,
            name: this.signupForm.firstName + " " + this.signupForm.lastName,
            company: this.signupForm.company,
            phone: this.signupForm.phone,
            terms: "on",
          });

          console.log("res getted");

          console.log("res: ", res);

          const data = res.data;

          console.log("data: ", data);

          if (data.status == "success") {
            this.$toast.add({
              severity: "success",
              summary: "Redirecting...",
              detail: data.message,
              life: 5000,
            });

            setTimeout(() => {
              window.location.href = data.redirect;
            }, 3000);
          } else {
            console.log(data);
          }
        }

        if (this.signupForm.channel == "hostaway") {
          this.visible = true;
        }

        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Code Verification Successful",
          life: 5000,
        });

        if (
          this.signupForm.channel != "ownerrez" &&
          this.signupForm.channel != "hostaway"
        ) {
          this.login();

          this.currentStage = this.STAGE_SIGNUP_SUCCESS;
        }
      } catch (e) {
        const msg = ErrorUtils.parseError(e);
        console.error(msg, e);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Sign Up Unsuccessful: " + msg,
          life: 10000,
        });
      }
    },

    async complete() {
      await TrustdService.saveUserInfo({
        username: this.signupForm.username,
        contactName: this.signupForm.firstName + " " + this.signupForm.lastName,
        company: this.signupForm.company,
        email: this.signupForm.username,
        website: null,
        phoneNumber: this.signupForm.phoneNumber,
        address: null,
        sendEmail: this.signupForm.sendEmail,
        channel: this.signupForm.channel,
        accountId: this.signupForm.accountId,
        apiKey: this.signupForm.apiKey,
        screening: 5,
        freeScreening: 0,
        status: "ACTIVE",
      });

      this.$toast.add({
        severity: "success",
        summary: "Signup Completed",
        detail: "Redirecting to login page...",
      });

      setTimeout(() => {
        this.login();
      }, 3000);
    },

    validateVerify() {
      let valid = true;

      if (StringUtils.isEmpty(this.verificationCode)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Verification Code is required",
          life: 5000,
        });
        valid = false;
      }
      return valid;
    },

    login() {
      this.$router.push("/");
    },

    resetVerify() {
      this.verificationCode = null;
    },

    checkTermsAndConditions() {
      if (this.signupForm.termsAndConditions === false) {
        this.openTermsAndConditions();
      }
    },

    openTermsAndConditions() {
      this.$refs.termsAndConditions.openServiceAgreement();
    },
  },
};
</script>

<style>
.p-dropdown {
  width: 100%;
}

.signupLeft {
  width: 20%;
}

.signupLeft h2 {
  font-size: 30px;
  font-weight: 700;
  color: #0086bf;
}

.signupLeft p {
  font-size: 16px;
}

.signupCard {
  min-width: 1132px;
}

.signupBody {
  width: 100%;
  display: flex;
  justify-content: center;
}

.signupBody .container-xxl {
  display: flex;
  justify-content: space-between;
  padding: 50px;
}

.signupLeft {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.signupBody .cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.signupBody .signupCard {
  margin: 0;
}

.signupCard h4 {
  font-weight: 700;
}

.signupBody .p-card-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}

.signupCard .input-container {
  min-width: 450px;
}

.signupBody .cardTitle {
  text-align: start !important;
  font-size: 30px;
  font-weight: 700;
  color: #0086bf;
}

.signupButtons {
  max-width: 450px;
}

.signupButtons label {
  font-size: 18px;
}

.signupButtons .checkbox-container {
  display: flex;
  gap: 20px;
}

.signupButtons input {
  width: 20px;
  height: 20px;
  border: none;
  background-color: #d9d9d9;
}

.signupCard a span:not(.secondary-a) {
  color: #00bbe1;
  text-decoration: underline;
  font-size: 18px;
}

.signupCard a .secondary-a {
  color: black;
  font-size: 18px;
}

@media screen and (max-width: 1200px) {
  .signupLeft {
    display: none;
  }

  .signupBody .container-xxl {
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  .signupCard {
    min-width: auto;
    width: 100%;
  }

  .cardContainer {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .signupBody .container-xxl {
    padding: 0;
  }

  .signupCard a span:not(.secondary-a) {
    font-size: 14px;
  }

  .signupCard a .secondary-a {
    font-size: 14px;
  }

  .signupLeft p {
    font-size: 14px;
  }

  .signupButtons label {
    font-size: 14px;
  }
}

@media screen and (max-width: 1150px) {
  .signupCard .input-container {
    min-width: auto;
  }

  .signupCard .p-card-content .row {
    width: 100%;
  }

  .signupCard .signupButtons {
    width: 100%;
    max-width: none;
  }
}
</style>
