<template>
  <div v-if="loading" class="loading-div">
    <ProgressSpinner
      style="width: 150px; height: 150px"
      strokeWidth="8"
      animationDuration="1.5s"
      aria-label="Custom ProgressSpinner"
    />
  </div>
  <div
    v-else-if="!this.formSubmitted"
    class="cardContainer secureGuestForm containerOverFlow"
    :class="{ 'mt-0': this.$store.state.shouldHideMenuNavigation }"
  >
    <Card class="cardCenter cardW50">
      <template #title>
        <div class="row mt-3">
          <div>
            <img
              alt="Trustd logo"
              src="../assets/trustd-logo.png"
              height="40"
              class="p-mr-1 logo-blend"
            />
          </div>
          <div class="mt-5">Secure Guest Form</div>
        </div>
        <div class="row mt-3">
          <div class="welcome-text">
            Hello {{ this.guestFullName }}, here are your reservation details
            for your upcoming trip to {{ this.guestForm.listingName }}. Please
            enter your date of birth and confirm that other information is
            accurate. Submit the form securely once you are done.
          </div>
        </div>
      </template>

      <template #content>
        <div
          class="mt-3 input-container"
          v-if="
            detailedHost &&
            detailedHost.isIdVerificationEnabled &&
            !imageIsUploaded
          "
        >
          <label> Image of your ID <sup class="validatedField">*</sup> </label>
          <input type="file" class="form-control" @change="handleFileChange" />
        </div>
        <div class="mt-3 input-container">
          <label> First Name <sup class="validatedField">*</sup> </label>
          <input
            type="text"
            class="form-control"
            v-model="guestForm.firstName"
          />
        </div>

        <div class="input-container mt-3">
          <label> Middle Name </label>
          <input
            type="text"
            class="form-control"
            v-model="guestForm.middleName"
          />
        </div>

        <div class="input-container mt-3">
          <label> Last Name <sup class="validatedField">*</sup> </label>
          <input
            type="text"
            class="form-control"
            v-model="guestForm.lastName"
          />
        </div>

        <div class="input-container mt-3">
          <label> Date of Birth<sup class="validatedField">*</sup> </label>
          <CustomCalendar
            class="p-button-sm"
            :showIcon="true"
            v-model="guestForm.birthDate"
            showButtonBar
          />
        </div>

        <div class="input-container mt-3">
          <label> CheckIn Date </label>
          <CustomCalendar
            class="p-button-sm"
            :showIcon="true"
            v-model="guestForm.checkInDate"
            showButtonBar
          />
        </div>

        <div class="input-container mt-3">
          <label> Checkout Date </label>
          <CustomCalendar
            class="p-button-sm"
            :showIcon="true"
            v-model="guestForm.checkOutDate"
            showButtonBar
          />
        </div>

        <div class="input-container mt-3">
          <label> Listing Name </label>
          <input
            type="text"
            class="form-control"
            v-model="guestForm.listingName"
            readonly
          />
        </div>

        <div class="input-container mt-3">
          <label> Listing Channel </label>
          <input
            type="text"
            class="form-control"
            v-model="guestForm.listingChannel"
            readonly
          />
        </div>
      </template>

      <template #footer>
        <div class="mb-3 row border-bottom">
          <div class="col-sm-12 form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="termsAndConditions"
              v-model="guestForm.termsAndConditions"
            />
            <label for="termsAndConditions" class="form-check-label">
              I hereby grant permission to Trustd.ai to carry out the guest
              screening process. I have read, comprehended, and accepted the
              detailed terms and conditions explained
              <strong>
                <span
                  class="terms-and-conditions"
                  @click.prevent="openTermsAndConditions"
                  >here</span
                >
              </strong>
            </label>
          </div>
        </div>

        <div class="mb-1" style="text-align: center">
          <Button class="btn btn-primary" label="Submit" @click="submit" />
        </div>
      </template>
    </Card>

    <Dialog
      :header="formSubmitDialog"
      v-model:visible="showFormSubmitDialog"
      :style="{ width: '50vw' }"
      :modal="true"
      :closable="false"
      :closeOnEscape="false"
    >
      <div>
        <span>Guest Form is submitted securely. Thank you.</span>
      </div>
      <template #footer>
        <div style="text-align: center">
          <Button
            label="Close"
            icon="pi pi-times"
            @click="closeGuestFormPage"
            autofocus
          ></Button>
        </div>
      </template>
    </Dialog>
  </div>
  <div v-else-if="this.showErrorDialog" class="submitInformation">
    <Card class="cardCenter cardW50 submitInformationCard">
      <template #content>
        <div class="submitInformationText">
          This link has already used or is not working anymore. If you think
          there is an issue please contact us at info@trustd.ai with URL address
          above.
        </div>
      </template>
    </Card>
  </div>
  <div v-else class="submitInformation">
    <Card class="cardCenter cardW50 submitInformationCard">
      <template #content>
        <div class="submitInformationText">You can close this page.</div>
      </template>
    </Card>
  </div>

  <terms-and-conditions ref="termsAndConditions"></terms-and-conditions>
</template>

<script>
import TermsAndConditions from "@/views/TermsAndConditions";
import DateUtils from "@/utils/DateUtils";
import StringUtils from "@/utils/StringUtils";
import TrustdService from "@/service/TrustdService";
import CustomCalendar from "../components/CustomCalendar";
import axios from "axios";

export default {
  name: "GuestForm",
  components: {
    TermsAndConditions,
    CustomCalendar,
  },
  props: ["id"],
  data() {
    return {
      guestForm: {
        firstName: "",
        middleName: "",
        lastName: "",
        birthDate: "",
        checkInDate: "",
        checkOutDate: "",
        listingName: "",
        listingChannel: "",
        termsAndConditions: false,
      },
      detailedHost: null,
      imageIsUploaded: false,
      guestFullName: "",
      hostEmail: "",
      guestEmail: "",
      guestPhone: "",
      formSubmitted: false,
      isUserSignedIn: false,
      formSubmitDialog: "",
      showFormSubmitDialog: false,
      showErrorDialog: false,
      submitInProgress: false,
      loading: false,
      bookingId: "",
    };
  },
  async mounted() {
    this.loading = true;

    if (this?.$store?.state) {
      this.isUserSignedIn = this.$store.state.isUserSignedIn;
      if (!this.$store.state.isUserSignedIn) {
        this.$store.commit("shouldHideMenuNavigation", true);
      }
    }

    const response = await TrustdService.getGuestFormRequestById(this.id);
    let guestFormRequest = response.guestFormRequest;

    console.log("guestFormRequest: ", guestFormRequest);

    if (guestFormRequest !== null && guestFormRequest.Status === "pending") {
      this.hostEmail = guestFormRequest.HostEmail;
      this.guestForm.firstName = guestFormRequest.GuestInfo.first_name;
      this.guestForm.middleName = guestFormRequest.GuestInfo.middle_name;
      this.guestForm.lastName = guestFormRequest.GuestInfo.last_name;
      this.guestForm.birthDate = guestFormRequest.GuestInfo.dob;
      this.guestForm.checkInDate = guestFormRequest.BookingInfo.check_in_date;
      this.guestForm.checkOutDate = guestFormRequest.BookingInfo.check_out_date;
      this.guestForm.listingName = guestFormRequest.BookingInfo.listing_name;
      this.guestForm.listingChannel =
        guestFormRequest.BookingInfo.listing_channel;
      this.guestEmail = guestFormRequest.GuestInfo.email;
      this.guestPhone = guestFormRequest.GuestInfo.phone;
      this.bookingId = guestFormRequest.BookingInfo.booking_id;

      this.guestFullName += this.guestForm.firstName;
      if (this.guestForm.middleName !== "") {
        this.guestFullName += " " + this.guestForm.middleName;
      }
      this.guestFullName += " " + this.guestForm.lastName;

      this.getDetailedHost();
    } else {
      this.formSubmitted = true;
      this.showErrorDialog = true;
    }

    this.loading = false;
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;

        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "ID is uploaded successfully!",
          life: 5000,
        });
      }
    },
    async uploadFile(reference) {
      let file = this.file;

      const formData = new FormData();
      formData.append("file", file);
      formData.append("first_name", this.guestForm.firstName);
      formData.append("last_name", this.guestForm.lastName);
      formData.append("birth_date", this.guestForm.birthDate);
      formData.append("trustd_reference", reference);

      try {
        const response = await axios.post(
          "/stripe/requests/id-verification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2",
            },
          }
        );
        console.log("File uploaded successfully", response.data);
      } catch (error) {
        console.error("Error uploading file", error);
      }
    },

    async getDetailedHost() {
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2", // todo
      };

      const response = await axios.get(
        "/stripe/users/" + this.hostEmail.toLowerCase(),
        { headers }
      );

      console.log("user info: ", response.data);

      this.detailedHost = response.data;
    },
    toggleTermsAndConditions() {
      this.guestForm.termsAndConditions = !this.guestForm.termsAndConditions;
    },

    async decreaseUserScreening(email) {
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2", // todo
      };

      await axios.get("/stripe/users/decrease-screening/" + email, {
        headers,
      });
    },

    openTermsAndConditions() {
      this.$refs.termsAndConditions.openTermsAndConditions();
      if (this.guestForm.termsAndConditions !== true) {
        this.guestForm.termsAndConditions = true;
      }
    },

    async submit() {
      if (this.submitInProgress) {
        this.$toast.add({
          severity: "error",
          summary: "Guest Form Save Error",
          detail: "Submit in progress! Please wait...",
          life: 5000,
        });
        return;
      }

      if (this.isIdVerificationEnabled && !this.imageIsUploaded) {
        this.$toast.add({
          severity: "error",
          summary: "Guest Form Save Error",
          detail: "You must upload your ID for verification!",
          life: 5000,
        });
        return;
      }

      if (this.isUserSignedIn) {
        this.$toast.add({
          severity: "error",
          summary: "Guest Form Save Error",
          detail: "Hosts can not sign guest forms!",
          life: 5000,
        });
        return;
      }

      if (!this.validateFields()) {
        return;
      }

      this.submitInProgress = true;
      this.loading = true;

      // call match api to check score
      const host = {
        attributes: {
          email: this.hostEmail,
        },
      };
      const matchApiPayload = {
        email: this.guestEmail,
        phone: this.guestPhone,
        firstName: this.guestForm.firstName,
        middleName: this.guestForm.middleName,
        lastName: this.guestForm.lastName,
        birthDate: this.guestForm.birthDate,
        listingName: this.guestForm.listingName,
        listingChannel: this.guestForm.listingChannel,
        checkInDate: this.guestForm.checkInDate,
        checkOutDate: this.guestForm.checkOutDate,
      };
      const matchApiResponse = await TrustdService.callMatchApi(
        matchApiPayload,
        host
      );

      const headers = {
        "Content-Type": "application/json",
        "x-api-key": "Sh7yxT0sFO1aujXJhKfo537kU0RgYkwTNdybZWH2", // todo
      };

      const response1 = await axios.get(
        "/stripe/users/" + this.hostEmail.toLowerCase(),
        { headers }
      );

      if (response1.data) {
        console.log("user info: ", response1.data);

        const payload = {
          email: this.hostEmail,
          type: "SCORE",
          pms: response1.data.channel,
        };

        await axios.post("/stripe/log", payload, {
          headers,
        });
      }

      await this.decreaseUserScreening(this.hostEmail);

      console.log("matchApiResponse: ", matchApiResponse);

      const trustdReference = matchApiResponse.trustdReference;

      // update guest form request with matchRequestId and completed status
      const guest = {
        email: this.guestEmail,
        phone: this.guestPhone,
        firstName: this.guestForm.firstName,
        middleName: this.guestForm.middleName,
        lastName: this.guestForm.lastName,
        dob: this.guestForm.birthDate,
      };
      const booking = {
        id: this.bookingId,
        listingName: this.guestForm.listingName,
        listingChannel: this.guestForm.listingChannel,
        checkInDate: this.guestForm.checkInDate,
        checkOutDate: this.guestForm.checkOutDate,
      };
      const response = await TrustdService.updateGuestFormRequest(
        this.id,
        guest,
        booking,
        "completed",
        trustdReference,
        this.hostEmail
      );

      console.log("guest form updated");

      const saveTagIfOwnerrez = await TrustdService.saveTagIfOwnerrez(
        this.hostEmail,
        matchApiResponse,
        guest,
        booking
      );
      const saveTagIfHostaway = await TrustdService.saveTagIfHostaway(
        this.hostEmail,
        matchApiResponse,
        guest,
        booking
      );

      console.log("saveTagIfOwnerrez: ", saveTagIfOwnerrez);

      console.log("saveTagIfHostaway: ", saveTagIfHostaway);

      if (response) {
        this.showFormSubmitDialog = true;
        this.submitInProgress = false;
      } else {
        console.log(response);
        this.$toast.add({
          severity: "error",
          summary: "Guest Form Save Error",
          detail: "Guest Form could not be saved!",
          life: 5000,
        });
        this.submitInProgress = false;
      }

      if (this.detailedHost && this.detailedHost.isIdVerificationEnabled) {
        this.uploadFile(trustdReference);
      }

      this.loading = false;
    },

    closeGuestFormPage() {
      this.showFormSubmitDialog = false;
      this.formSubmitted = true;
    },

    validateFields() {
      let valid = true;

      if (StringUtils.isEmpty(this.guestForm.firstName)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "First Name is required",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.guestForm.lastName)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Last Name is required",
          life: 5000,
        });
        valid = false;
      }

      if (!DateUtils.isValid(this.guestForm.birthDate)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Date of Birth is required",
          life: 5000,
        });
        valid = false;
      }

      if (!DateUtils.isValid(this.guestForm.checkInDate)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "CheckIn Date is required",
          life: 5000,
        });
        valid = false;
      }

      if (!DateUtils.isValid(this.guestForm.checkOutDate)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Checkout Date is required",
          life: 5000,
        });
        valid = false;
      }

      if (this.guestForm.termsAndConditions === false) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Please check terms and conditions",
          life: 5000,
        });
        valid = false;
      }

      return valid;
    },
  },
};
</script>

<style scoped>
.terms-and-conditions {
  cursor: pointer;
  color: #6366f1;
}

.submitInformation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.submitInformationText {
  text-align: center;
  font-weight: 700;
  font-size: large;
}

.mt-0 {
  margin-top: 0;
}

.loading-div {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 60px);
}

.welcome-text {
  font-size: medium;
  font-weight: 400;
}
</style>
