<template>
  <div class="cardContainer">
    <Card class="cardCenter cardW50">
      <template #content>
        <h4 class="mb-5" style="color: #0086bf">Update Password</h4>
        <div class="mb-3" v-if="passwordUser == null">
          <label for="currentPassword" class="form-label"
            >Current<sup class="validatedField">*</sup></label
          >
          <input
            type="password"
            class="form-control"
            id="currentPassword"
            placeholder="Current"
            v-model="passwordForm.currentPassword"
          />
        </div>
        <div class="mb-3">
          <label for="newPassword1" class="form-label"
            >New Password<sup class="validatedField">*</sup></label
          >
          <input
            type="password"
            class="form-control"
            id="newPassword1"
            placeholder="New Password"
            v-model="passwordForm.newPassword1"
          />
        </div>
        <div class="mb-3">
          <label for="newPassword2" class="form-label"
            >Re-enter New Password<sup class="validatedField">*</sup></label
          >
          <input
            type="password"
            class="form-control"
            id="newPassword2"
            placeholder="Re-enter New Password"
            v-model="passwordForm.newPassword2"
          />
        </div>
        <div
          class="mb-1 d-flex justify-content-between flex-wrap bottomButtons"
        >
          <Button
            class="btn btn-primary"
            label="Update"
            @click="updatePassword"
          />
          &nbsp;
          <Button
            label="Cancel"
            class="btn btn-primary p-button-warning"
            @click="cancel"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import AuthService from "@/service/AmplifyAuth";
import StringUtils from "@/utils/StringUtils";
import ErrorUtils from "@/utils/ErrorUtils";

export default {
  name: "ChangePassword",
  props: {
    passwordUser: Object,
    cancelCallBack: Function,
    parentComponent: Object,
  },
  data() {
    return {
      passwordForm: {
        currentPassword: "",
        newPassword1: "",
        newPassword2: "",
      },
    };
  },
  methods: {
    async updatePassword() {
      if (!this.validatePassword()) {
        return;
      }
      try {
        console.log("clicked update password");
        if (this.passwordUser != null) {
          console.log("password change required user: ", this.passwordUser);
          const user = await AuthService.completeNewPassword(
            this.passwordUser,
            this.passwordForm.newPassword1
          );
          this.$store.commit("signInUser", user);
          await this.$router.push("/");
        } else {
          const user = await AuthService.currentAuthenticatedUser();
          console.log("authenticated user: ", this.passwordUser);
          await AuthService.changePassword(
            user,
            this.passwordForm.currentPassword,
            this.passwordForm.newPassword1
          );
          await this.$router.push("/account");
        }
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Password Update Successful",
          life: 5000,
        });
      } catch (e) {
        const msg = ErrorUtils.parseError(e);
        console.error(msg, e);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Password Update Unsuccessful: " + msg,
          life: 10000,
        });
      }
    },

    validatePassword() {
      let valid = true;

      if (
        this.passwordUser == null &&
        StringUtils.isEmpty(this.passwordForm.currentPassword)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Current Password is required",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.passwordForm.newPassword1)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "New Password is required",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.passwordForm.newPassword2)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "New Password ReEntry is required",
          life: 5000,
        });
        valid = false;
      }

      if (this.passwordForm.newPassword1 !== this.passwordForm.newPassword2) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "New Password and New Password ReEntry do not match",
          life: 5000,
        });
        valid = false;
      }

      return valid;
    },

    cancel() {
      if (this.passwordUser == null) {
        this.$toast.add({
          severity: "info",
          summary: "Info",
          detail: "Password update cancelled",
          life: 5000,
        });
        this.$router.push("/account");
      } else {
        this.$toast.add({
          severity: "info",
          summary: "Info",
          detail: "Required Password update cancelled",
          life: 5000,
        });
        this.cancelCallBack(this.parentComponent);
      }
    },
  },
};
</script>

<style scoped></style>
